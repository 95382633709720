import React from 'react'
import Footer from '../layout/Footer'
import Navbar from '../layout/Navbar'
import ParticlesBackground from '../particlesBackground'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import {useTranslation} from 'react-i18next'

const ProjectsPage = () => {
  const {t} = useTranslation()
  return (
    <div className="animate__animated animate__fadeIn animate__slow">
      <ParticlesBackground />
      <Navbar />
      <div className="mb-10 px-8 md:px-16 lg:px-36 grid grid-cols-1 space-y-16">
        <div className="text-3xl md:text-4xl lg:text-5xl text-white text-center font-bold uppercase">{t("our-projects")}</div>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="flex justify-center md:sticky md:top-20 md:h-48 w-full">
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <img
                src="images/Turgay_Ince.png"
                className="img-responsive w-48 h-48 border-white border-2 rounded-full"
                alt=""
              />
              <h1 className="text-white text-center text-2xl lg:text-3xl">{t("project-Ceo")}</h1>
            </AnimationOnScroll>
          </div>
          <div className="rounded-xl">
            <div className="">
              <div className="backdrop-blur-sm rounded-xl p-4">
                <div className="text-lg md:text-xl lg:text-2xl grid grd-cols-1 gap-y-8">
                  <AnimationOnScroll animateIn="animate__fadeInRight">
                    <p className="bg-gradient-to-r from-white to-[#B6B6B6] rounded-lg p-4">
                      {t("Projects-1")}
                    </p>
                  </AnimationOnScroll>

                  <AnimationOnScroll animateIn="animate__fadeInRight">
                    <p className="bg-gradient-to-r from-[#B6B6B6]  to-white rounded-lg p-4">
                      {t("Projects-2")}
                    </p>
                  </AnimationOnScroll>

                  <AnimationOnScroll animateIn="animate__fadeInRight">
                    <p className="bg-gradient-to-r from-white to-[#B6B6B6]  rounded-lg p-4">
                      {t("Projects-3")}
                    </p>
                  </AnimationOnScroll>

                  <AnimationOnScroll animateIn="animate__fadeInRight">
                    <p className="bg-gradient-to-r from-[#B6B6B6]  to-white rounded-lg p-4">
                      {t("Projects-4")}
                    </p>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__fadeInRight">
                    <p className="bg-gradient-to-r from-white to-[#B6B6B6]  rounded-lg p-4">
                      {t("Projects-5")}
                    </p>
                  </AnimationOnScroll>

                  <AnimationOnScroll animateIn="animate__fadeInRight">
                    <p className="bg-gradient-to-r from-[#B6B6B6]  to-white rounded-lg p-4">
                      {t("Projects-6")}
                    </p>
                  </AnimationOnScroll>

                  <AnimationOnScroll animateIn="animate__fadeInRight">
                    <p className="bg-gradient-to-r from-white to-[#B6B6B6]  rounded-lg p-4">
                      {t("Projects-7")}
                    </p>
                  </AnimationOnScroll>

                  <AnimationOnScroll animateIn="animate__fadeInRight">
                    <p className="bg-gradient-to-r from-[#B6B6B6]  to-white rounded-lg p-4">
                      {t('Projects-8')}
                    </p>
                  </AnimationOnScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="text-lg md:text-xl lg:text-2xl p-4 rounded-xl grid grid-cols-1 gap-y-8">
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <p className="bg-gradient-to-r from-white to-[#B6B6B6]  rounded-lg p-4">
                {t("Projects-9")}
              </p>
            </AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <p className="bg-gradient-to-r from-[#B6B6B6] to-white rounded-lg p-4">
                {t("Projects-10")}
              </p>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <p className="bg-gradient-to-r from-white to-[#B6B6B6]  rounded-lg p-4">
                {t("Projects-11")}
              </p>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <p className="bg-gradient-to-r from-[#B6B6B6]  to-white rounded-lg p-4">
                {t("Projects-12")}
              </p>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <p className="bg-gradient-to-r from-white to-[#B6B6B6]  rounded-lg p-4">
                {t("Projects-13")}
              </p>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <p className="bg-gradient-to-r from-[#B6B6B6]  to-white rounded-lg p-4">
                {t("Projects-14")}
              </p>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <p className="bg-gradient-to-r from-white to-[#B6B6B6]  rounded-lg p-4">
                {t("Projects-15")}
              </p>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <p className="bg-gradient-to-r from-[#B6B6B6]  to-white rounded-lg p-4">
                {t("Projects-16")}
              </p>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <p className="bg-gradient-to-r from-white to-[#B6B6B6]  rounded-lg p-4">
                {t("Projects-17")}
              </p>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <p className="bg-gradient-to-r from-[#B6B6B6] to-white rounded-lg p-4">
                {t("Projects-18")}
              </p>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <p className="bg-gradient-to-r from-white to-[#B6B6B6] rounded-lg p-4">
                {t("Projects-19")}
              </p>
            </AnimationOnScroll>
          </div>
          <div className="flex order-first md:order-none justify-center md:sticky md:top-20 md:h-48 w-full">
            <AnimationOnScroll animateIn="animate__fadeInRight">
              <img
                src="images/Nejdet_Ozen.png"
                className="img-responsive w-48 h-48 border-white border-2 rounded-full"
                alt=""
              />
              <h1 className="text-white text-2xl  lg:text-3xl text-center">{t("project-Ceo2")}</h1>
            </AnimationOnScroll>
          </div>
        </div>
        <hr />
        <div>
          <h1 className="text-4xl text-center text-white">
            {t("current-project")}
          </h1>
          <div className="text-lg md:text-xl lg:text-2xl mt-10 flex flex-wrap justify-center gap-10 items-center">
          <div className="text-white  ">
            <p>
              <span className="font-bold">{t("project-place")}</span>{' '}
              <span className="">{t("project-place-name")}</span>
            </p>
            <p>
              <span className="font-bold">{t("project-name")}</span>{' '}
              <span className="">Cenergo 240 MW CCCP PROJECT</span>
            </p>
            <p>
              <span className="font-bold">{t("project-task")}</span>{' '}
              <span className="">{t("project-task-name")}</span>
            </p>
          </div>
          <div className="">
            <img src="images/9.jpg" alt="" className="rounded-xl w-96" />
          </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ProjectsPage
