import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'

const Footer = () => {
  const { t } = useTranslation()
  return (
    <div className="mt-4">
      <div className="w-full bg-[#282828] pt-3 px-4 lg:px-20 text-lg">
        <div className="border-t-2 border-white">
          <div className="flex justify-center items-center flex-wrap mx-16 md:mx-0 gap-x-6 gap-y-2 pt-2 text-white">
            <h1 className="text-3xl lg:hidden mb-2 text-center">
              Следите за нами!
            </h1>
            <div className="flex gap-2 mb-4 md:mb-0  md:mx-0 basis-full lg:basis-auto justify-center ">
              <img
                className="inline w-7"
                src="images/icons/facebook.svg"
                alt=""
              />
              <img
                className="inline w-7"
                src="images/icons/twitter.svg"
                alt=""
              />
              <img
                className="inline w-7"
                src="images/icons/instagram.svg"
                alt=""
              />
              <Link to=''>
              <img
                className="inline w-7"
                src="images/icons/Telegram.svg"
                alt=""
              />
              </Link>
            </div>
            <div className="">
              <div className="flex justify-center md:inline">
                <img
                  src="images/icons/phone.svg"
                  className="w-7 md:inline md:mr-2"
                  alt=""
                />
              </div>
              <span className=""> <a href="tel:+998 90 035 02 00">+998 90 035 02 00</a> </span>
            </div>
            <div>
              <div className="flex justify-center md:inline">
                <img
                  src="images/icons/location.svg"
                  className="w-7 md:inline md:mr-2"
                  alt=""
                />
              </div>
              <div className="md:inline text-center">
                {t("location")}
              </div>
            </div>
            <div>
              <div className="flex justify-center md:inline">
                <img
                  src="images/icons/mail.svg"
                  className="w-7 md:inline  md:mr-2"
                  alt=""
                />
              </div>
              <span> <a href="mailto:info@meras.uz">info@meras.uz</a> </span>
            </div>
          </div>
          <div>
            <p className="text-center text-white text-sm my-2 ">
              © 2022 — {t("copyright")}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
