import React, { useEffect, useMemo, useRef, useState } from 'react'
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api'
import Navbar from '../layout/Navbar'
import Footer from '../layout/Footer'
import ParticlesBackground from '../particlesBackground'
import emailjs from '@emailjs/browser'
import {useTranslation} from 'react-i18next'

const ContactPage = () => {
  const {t} = useTranslation()
  const form = useRef();

  const SERVICE_ID=process.env.REACT_APP_EMAIL_SERVICE_ID
  const TEMPLATE_ID=process.env.REACT_APP_EMAIL_TEMPLATE_ID
  const PUBLIC_KEY=process.env.REACT_APP_EMAIL_PUBLIC_KEY

  const [status, setStatus] = useState(false);
  
  const sendEmail = (e) => {
    
    e.preventDefault();

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY)
    .then((result) => {
      console.log("Success!", result.text);
      setStatus(true)
      e.target.reset();
    }, (error) => {
      console.log('Failed', error.text);
      setStatus(false)
    })
  }

  useEffect(() => {
    if(status) {
      setTimeout(() => {
        setStatus(false)
      }, 3000)
    }
  }, [status])


  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  })

  const center = useMemo(
    () => ({ lat: 41.31409563376729, lng:  69.28732134131252 }),
    [],
  )

  return (
    <div className='animate__animated animate__fadeIn animate__slow'>
      {status ? 
      <div className={`animate__animated animate__fadeInRight fixed z-50 bottom-16 right-4 px-8 py-4 bg-green-500 text-white font-semibold rounded-lg `}>
        <p className='text-xl'>{t("success-message")}</p>
      </div>
       : ''}
      <ParticlesBackground />
      <Navbar />
      <div className="relative w-full ">
        <div className="absolute w-full min-h-screen ">
          <div
            className="absolute z-0 top-0 w-full h-1/2 bg-cover contact-bg"
            style={{ backgroundImage: `url("images/6-black.jpg")` }}
          >
            
              <h1 className="text-white text-3xl md:text-4xl lg:text-5xl font-bold text-center py-10 uppercase">
                {t("contact-us")}
              </h1>
            
          </div>
        </div>
        <div className="relative z-10 flex  justify-center lg:items-center">
          <div className="relative">
            <div className="animate__animated animate__zoomIn relative z-20 bg-white p-12 shadow-lg text-lg rounded-xl max-w-5xl mt-36  mb-10 grid grid-cols-1 lg:grid-cols-2 lg:gap-x-10">
              <div>
                <div>
                  <h1 className='text-center text-4xl mb-8 font-bold'>{t("contact-info")}</h1>
                </div>
                <div className="flex flex-wrap justify-center">
                  <div className="text-center md:basis-1/4 lg:basis-1/4 mb-5">
                    <img
                      src="images/icons/mail-black.svg"
                      className="w-8 inline"
                      alt=""
                    />
                    <p className=" pt-5">info@meras.uz</p>
                  </div>
                  <div className="text-center md:basis-1/2 lg:basis-1/2 mb-5">
                    <img
                      src="images/icons/location-black.svg"
                      className="w-8 inline"
                      alt=""
                    />
                    <p className="pt-5">
                      {t("location")}
                    </p>
                  </div>
                  <div className="text-center md:basis-1/4 lg:basis-1/4 mb-5 ">
                    <img
                      src="images/icons/phone-black.svg"
                      className="w-8 inline"
                      alt=""
                    />

                    <p className="pt-5">+998 90 035 02 00</p>
                  </div>
                </div>
                <div>
                  <h1 className='text-2xl text-right'>
                    {t("ask")}
                  </h1>
                </div>
                <form onSubmit={sendEmail} ref={form} className="py-6 lg:py-0">
                
                
                  <div className="mt-4 grid grid-cols-1 md:grid-cols-2 md:space-x-4 gap-y-6">
                    <input
                      name='name'
                      type="text"
                      placeholder={t("name")}
                      className="border border-gray-200 outline-none px-4 py-2 rounded-md hover:border-[#282828] focus:border-ripelemon-500"
                    />
                    <input
                      name='email'
                      type="email"
                      placeholder={t("email")}
                      className="border border-gray-200 outline-none px-4 py-2 rounded-md hover:border-[#282828] focus:border-ripelemon-500"
                    />
                  </div>
                  <div className="grid grid-cols-1 mt-8">
                    <textarea
                      name={t("message")}
                      id=""
                      cols="30"
                      rows="5"
                      placeholder="Комментарий"
                      className="border border-gray-200 outline-none px-4 py-2 rounded-md hover:border-[#282828] focus:border-ripelemon-500 md:col-span-3"
                    ></textarea>
                  </div>
                  <div className="text-right">
                    <button type="submit"  className="inline-block w-auto mt-4 px-6 py-2 bg-[#282828] rounded-md shadow-md text-white font-bold"  >
                    {t("send")}
                  </button>
                  
                  </div>
                </form>
              </div>
              <div>
                  <div className='-mx-12 md:mx-0 '>
                    {isLoaded ? (
                      <GoogleMap
                        zoom={17}
                        center={center}
                        mapContainerClassName="map-container"
                      >
                        <Marker position={center} />
                      </GoogleMap>
                    ) : (
                      <div>Loading...</div>
                    )}
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Map */}
      <Footer />
    </div>
  )
}

export default ContactPage
