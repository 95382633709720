import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Navbar = (props) => {
  const {i18n} = useTranslation()
  const { t } = useTranslation()

  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value)
  }
  const [isActive, setActive] = useState(false)
  function toggleHandler() {
    setActive(!isActive)
  }

  const location = useLocation();

  const activePage = {
    backgroundColor: "#000",
    border: "2px solid white",
    color: "#fff"
  }

  return (
    <div className='mb-4 w-full'>
      <div className={props.styleDiv}>
        <div className="xl:px-24 md:px-8  py-5">
          <nav className=" text-lg md:text-xl xl:text-2xl uppercase">
            <div className='flex justify-between md:justify-center items-center'>
              <div className='pl-6'>
                <Link to={'/'}>
                  <img src="images/logo.png" alt="Meras.uz logo"  className="w-28 md:w-32 xl:w-40 md:mr-8 xl:mr-12" />
                </Link>
              </div>
              <div className="hidden md:flex md:gap-6 lg:gap-10 xl:gap-14 ">
                <Link
                  to={'/about'}
                  style={location.pathname === "/about" ? activePage:{}}
                  className="bg-white md:px-3 xl:px-7 py-2 xl:py-2 md:py-1 rounded-full hover:bg-black hover:text-white duration-300 hover:border-white border-2"
                >
                  {t("nav-about")}
                </Link>
                <Link
                  to={'/services'}
                  style={location.pathname === "/services" ? activePage:{}}
                  className="bg-white md:px-3 xl:px-7 py-2 xl:py-2 md:py-1 rounded-full hover:bg-black hover:text-white duration-300 hover:border-white border-2"
                >
                  {t("nav-service")}
                </Link>
                <Link
                  to={'/products'}
                  style={location.pathname === "/products" ? activePage:{}}
                  className="bg-white md:px-3 xl:px-7 py-2 xl:py-2 md:py-1 rounded-full hover:bg-black hover:text-white duration-300 hover:border-white border-2"
                >
                  {t("nav-products")}
                </Link>
                <Link
                  to={'/projects'}
                  style={location.pathname === "/projects" ? activePage:{}}
                  className="bg-white md:px-3 xl:px-7 py-2 xl:py-2 md:py-1 rounded-full hover:bg-black hover:text-white duration-300 hover:border-white border-2"
                >
                  {t("nav-projects")}
                </Link>
                <Link
                  to={'/contact'}
                  style={location.pathname === "/contact" ? activePage:{}}
                  className="bg-white md:px-3 xl:px-7 py-2 xl:py-2 md:py-1 rounded-full hover:bg-black hover:text-white duration-300 hover:border-white border-2"
                >
                  {t("nav-contact")}
                </Link>
                
              </div>
              {/* Mobile menu button */}
              <div className="md:hidden pr-4">
                <button onClick={toggleHandler}>
                  <svg
                    fill="#ffffff"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 30 30"
                    width="30px"
                    height="30px"
                  >
                    <path d="M 3 7 A 1.0001 1.0001 0 1 0 3 9 L 27 9 A 1.0001 1.0001 0 1 0 27 7 L 3 7 z M 3 14 A 1.0001 1.0001 0 1 0 3 16 L 27 16 A 1.0001 1.0001 0 1 0 27 14 L 3 14 z M 3 21 A 1.0001 1.0001 0 1 0 3 23 L 27 23 A 1.0001 1.0001 0 1 0 27 21 L 3 21 z" />
                  </svg>
                </button>
              </div>
              <div class="hidden group md:inline-block relative md:ml-4 lg:ml-6">
                <button class="rounded inline-flex items-center">
                  <img
                    src={`images/icons/${t("flag-sw")}-flag.svg`}
                    className="inline  w-4"
                    alt=""
                  />
                  
                  <svg
                    class="fill-current h-4 w-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </button>

                <ul class="absolute z-50 hidden pt-1 group-hover:block">
                  

                  <li class="">
                    <button
                      onClick={changeLanguage}
                      class="bg-white hover:bg-gray-400 py-1 px-2 relative block whitespace-no-wrap w-16"
                      value="en"
                    >
                      <img
                        src="images/icons/en-flag.svg"
                        alt=""
                        className="inline w-4 z-0 "
                      />{" "}
                      Eng
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={changeLanguage}
                      class="bg-white hover:bg-gray-400 py-1 px-2 relative block whitespace-no-wrap w-16"
                      value="ru"
                    >
                      <img
                        src="images/icons/ru-flag.svg"
                        alt=""
                        className="inline w-4 z-0"
                      />{" "}
                      Рус
                    </button>
                  </li>
                </ul>
              </div>
            </div>
      
            {/* Mobile menu */}
            <div className= {isActive ? "absolute w-full z-50 text-center py-1 bg-white border rounded-b-xl md:hidden" : "hidden" }>
      
            {/* <div className="my-2">
            <Link
                onClick={toggleHandler}
                to={'/'}
                className="px-8 py-1 rounded-full hover:bg-black hover:text-white duration-300 "
              >
                Главная
              </Link>
              </div> */}
            <div className="my-2">
            <Link
                onClick={toggleHandler}
                to={'/about'}
                className="px-8 py-1 rounded-full hover:bg-black hover:text-white duration-300 "
              >
               {t("nav-about")}
              </Link>
              </div>
              <div className="my-2">
                <Link
                  onClick={toggleHandler}
                  to={'/services'}
                  className="px-8 py-1 rounded-full hover:bg-black hover:text-white duration-300 "
                >
                  {t("nav-service")}
                </Link>
              </div>
              <div className="my-2">
                <Link
                  onClick={toggleHandler}
                  to={'/products'}
                  className="px-8 py-1 rounded-full hover:bg-black hover:text-white duration-300 "
                >
                  {t("nav-products")}
                </Link>
              </div>
              <div className="my-2">
                <Link
                  onClick={toggleHandler}
                  to={'/projects'}
                  className="px-8 py-1 rounded-full hover:bg-black hover:text-white duration-300 "
                >
                  {t("nav-projects")}
                </Link>
              </div>
              <div className="my-2">
                <Link
                  onClick={toggleHandler}
                  to={'/contact'}
                  className="my-2 px-8 py-1 rounded-full hover:bg-black hover:text-white duration-300 "
                >
                  {t("nav-contact")}
                </Link>
              </div>
              <div className="flex justify-center">
                <button
                  className="px-2"
                  onClick={changeLanguage}
                  value="ru"
                >
                  <img src="images/icons/ru-flag.svg" alt="" className="w-5"/> Руc
                </button>
                <button
                className="px-2"
                onClick={changeLanguage}
                value="en"
                >
                <img src="images/icons/en-flag.svg" alt="" className="w-5"/> Eng
                </button>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Navbar
