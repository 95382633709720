import React from 'react'
import Navbar from '../layout/Navbar'
import Footer from '../layout/Footer'
import ReactPlayer from 'react-player'
import {AnimationOnScroll} from 'react-animation-on-scroll'
import { useTranslation } from 'react-i18next'

const ProductsPage = () => {
  const {t} = useTranslation()
  return (
    <div className=" animate__animated animate__fadeIn animate__slow">
      <Navbar />

      <div className="px-8 md:px-24 lg:px-36 mb-10">
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center uppercase text-white">{t("our-products")}</h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-10 gap-x-6 items-center mt-10  products-container">
         
        <AnimationOnScroll animateIn="animate__fadeInLeft">
          <div>
            <img src="images/products/LIGHTNING-SYSTEM.jpg" alt="" className="w-full" />
          </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInRight" className="order-first md:order-none">
          <div className="">
            <h1 className="text-xl md:text-2xl lg:text-3xl mb-8 text-center bg-white rounded-xl p-6">
              {t("Products-1")}
            </h1> 
            
            <div className="bg-white rounded-xl p-4">
            
            <ReactPlayer light={"images/products/video_thumbnail.jpg"} controls={true} playing={true} width={'100%'} url='images/products/EFENS ENERJİ SAVUNMA LIGHTING SYSTEMS.mp4' />

            </div>
          </div>
          </AnimationOnScroll>

          <AnimationOnScroll animateIn="animate__fadeInLeft">
          <div className="">
            <h1 className="text-xl md:text-2xl lg:text-3xl mb-8 text-center bg-white rounded-xl p-6">
              {t("Products-2")}
            </h1>
            <div className="bg-white p-6 rounded-xl">
            <img src="images/products/FIRE-ALARM.jpg" alt="" className="w-full" />
            </div>
          </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInRight">
          <div>
            <img src="images/products/FIRE-ALARM-SYSTEM.jpg" alt="" className="w-full" />
          </div>
          </AnimationOnScroll>

          <AnimationOnScroll animateIn="animate__fadeInLeft">
          <div className="bg-white p-6 rounded-xl">
            <img
              src="images/products/TYCO-FIRE-SUPPRESSION-SYSTEM.jpg"
              alt=""
            />
          </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInRight" className="">
          <div className="rounded-xl">
            <div className="bg-white p-6 rounded-xl mb-8">
              <h1 className="text-xl md:text-2xl lg:text-3xl">
                {t("Products-3")}
              </h1>
            </div>
            <div className="bg-white p-6 rounded-xl"><img src="images/products/TYCO-FIRE-SYSTEM.jpg" alt="" /></div>
          </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInLeft">
          <div className="bg-white p-6 rounded-xl">
            <h1 className="text-xl md:text-2xl lg:text-3xl ">
            {t("Products-4")}
            </h1>
            
          </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInRight">
          <div className="bg-white p-6 rounded-xl">
          <img src="images/products/COOLING-SYSTEM.jpg" alt="" />
          </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInLeft">
          <div className="bg-white p-6 rounded-xl">
            <img
              src="images/products/COOLING-SYSTEM-DESIGN-SAMPLE.jpg"
              alt=""
            />
          </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInRight">
          <div className="bg-white p-6 rounded-xl">
            <h1 className="text-xl md:text-2xl lg:text-3xl ">
            {t("Products-5")}
            </h1>
            
          </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInLeft">
          <div className="bg-white p-6 rounded-xl">
            <h1 className="text-xl md:text-2xl lg:text-3xl ">
            {t("Products-6")}
            </h1>
            
          </div>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInRight">
          <div className="bg-white p-6 rounded-xl">
            <img src="images/products/ELECTRICAL-DISTRIBUTION-PANEL.jpg" alt="" />
          </div>
          </AnimationOnScroll>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default ProductsPage
