import {Routes, Route} from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import ServicesPage from "./pages/ServicesPage";
import ProjectsPage from "./pages/ProjectsPage";
import ProductsPage from "./pages/ProductsPage";



function App() {
  return (
    <div className="App">
          <Routes>
            <Route path="/" element={<HomePage />} exact></Route>
            <Route path="/about" element={<AboutPage />}></Route>
            <Route path="/contact" element={<ContactPage />}></Route>
            <Route path="/services" element={<ServicesPage />}></Route>
            <Route path="/projects" element={<ProjectsPage />}></Route>
            <Route path="/products" element={<ProductsPage />}></Route>
          </Routes>       
    </div>
  );
}

export default App;
