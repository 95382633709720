import React from 'react'
import Footer from '../layout/Footer'
import Navbar from '../layout/Navbar'
import ParticlesBackground from '../particlesBackground'
import { useTranslation } from 'react-i18next'

const ServicesPage = () => {
  const {t} = useTranslation()
  return (
    <div className="-mt-4 animate__animated animate__fadeIn animate__slow">
      <ParticlesBackground />
      <Navbar styleDiv="absolute z-50 w-full" />
      <div className="pt-24 bg-cover bg-center bg-services lg:h-screen grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 items-center text-white">
        <div className="lg:hidden md:col-span-2 mb-10">
          <img src="images/water.jpg" alt="" className="rounded-xl" />
        </div>

        <div className="lg:justify-self-start justify-self-center mt-10  lg:pl-16 animate__animated animate__fadeInBottomRight ">
          <h1 className="text-4xl 2xl:text-4xl 3xl:text-5xl  font-bold text-center ">{t("Service-electro-title")}</h1>
          <ul className="text-lg 2xl:text-xl 3xl:text-3xl">
            <li>{t("service-electro-1")}</li>
            <li>{t("service-electro-2")}</li>
            <li>{t("service-electro-3")}</li>
            <li>{t("service-electro-4")}</li>
            <li>{t("service-electro-5")}</li>
            <li>{t("service-electro-6")}</li>
            <li>{t("service-electro-7")}</li>
          </ul>
        </div>

        <div className="lg:justify-self-end justify-self-center order-last md:order-none animate__animated animate__fadeInTopLeft">
          <h1 className="text-4xl 2xl:text-4xl 3xl:text-5xl font-bold text-center ">
            {t("Service-mechanic-title")}
          </h1>
          <ul className="text-lg 2xl:text-xl 3xl:text-3xl ">
            <li>{t("service-mechanic-1")}</li>
            <li>{t("service-mechanic-2")}</li>
            <li>{t("service-mechanic-3")}</li>
            <li>{t("service-mechanic-4")}</li>
            <li>{t("service-mechanic-5")}</li>
            <li>{t("service-mechanic-6")}</li>
          </ul>
        </div>
        <div className="lg:hidden md:col-span-2 mt-10 mb-10">
          <img src="images/electromechanic.jpg" alt="" className="rounded-xl" />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ServicesPage
