import React from 'react'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation, Autoplay } from 'swiper'
import Navbar from '../layout/Navbar'
import Footer from '../layout/Footer'
import ParticlesBackground from '../particlesBackground'
import { useTranslation } from 'react-i18next'

const AboutPage = () => {
  const {t} = useTranslation()
  return (
    <div className='animate__animated animate__fadeIn animate__slow'>
      <ParticlesBackground />
      <Navbar />
      <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center uppercase text-white">{t("about-us")}</h1>
      <div className="px-8 md:px-24 lg:px-36 lg:my-20 xl:my-24">
      
        <div className="grid grid-cols-1 animate__animated animate__fadeInUp">
          <div className="grid grid-cols-1 md:grid-cols-2 md:divide-x-2 mb-6 md:mb-10 lg:mb-14">
            <div className="md:pr-8 mb-10 md:mb-0 ">
              <p className="text-white text-2xl">
                {t("About-1")}
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-y-8 md:gap-8 lg:gap-16 md:pl-8 ">
              <div className="mx-auto relative z-10">
                <img
                  className="rounded-full border-dashed border-white border-2 w-36 left-0 "
                  src="images/Turgay_Ince.png"
                  alt=""
                />
              </div>
              <div className="col-span-2">
                <h1 className="mx-10 text-center text-3xl font-bold  text-white italic">
                  {t("Ceo-position")}
                </h1>
                <p className="text-white text-xl text-justify">
                  {t("About-ceo")}
                </p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 md:divide-x-2">
            <div className="md:pr-8">
              <p className="text-white text-2xl mb-10 md:mb-0">
               {t("About-2")}
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-y-8 md:gap-8 lg:gap-16 md:pl-8 mb-10">
              <div className="mx-auto relative z-10">
                <img
                  className="rounded-full w-44 border-dashed border-white border-2"
                  src="images/Nejdet_Ozen.png"
                  alt=""
                />
              </div>
              <div className="col-span-2">
                <h1 className="text-center mx-10 text-3xl font-bold  text-white italic">
                  {t("Ceo-2-position")}
                </h1>
                <p className="text-white text-xl  text-justify">
                  {t("About-ceo2")}
                </p>
              </div>
            </div>
          </div>
          <div className=" md:mt-24 h-12 flex">
            <Swiper
              slidesPerView={5}
              spaceBetween={40}
              slidesPerGroup={1}
              loop={true}
              autoplay={true}
              navigation={true}
              modules={[Pagination, Navigation, Autoplay]}
              className="mySwiper"
              breakpoints={{
                320: {
                  width: 320,
                  slidesPerView: 1,
                },
                480: {
                  width: 480,
                  slidesPerView: 1,
                },
                640: {
                  width: 640,
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                768: {
                  width: 768,
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                1024: {
                  width: 1024,
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
              }}
            >
              
              
              <SwiperSlide className="flex justify-center items-center">
                <a target="blank" href="https://www.daikin.com/"><img src="images/partners/Daikin.png" className="h-12" alt="" /></a>
              </SwiperSlide>
              <SwiperSlide className="flex justify-center items-center">
                <a target="blank" href="https://www.fraenkische.com/"><img src="images/partners/Frankische.svg" className="h-10" alt="" /></a>
              </SwiperSlide>
              <SwiperSlide className="flex justify-center items-center">
                <a target="blank" href="https://www.midea.com/" ><img src="images/partners/Midea.png" className="h-12" alt="" /></a>
              </SwiperSlide>
              <SwiperSlide className="flex justify-center items-center">
                <a target="blank" href="https://www.honeywell.com/"><img src="images/partners/Honeywell.png" className="h-12" alt="" /></a>
              </SwiperSlide>
              <SwiperSlide className="flex justify-center items-center ">
                <a target="blank" href="https://www.tyco.com/"><img src="images/partners/tyco2.png" className="h-12" alt="" /></a>
              </SwiperSlide>
              


              <SwiperSlide className="flex justify-center items-center">
                <a target="blank" href="https://www.systemair.com/"><img src="images/partners/systemair.png" className="h-12" alt="" /></a>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AboutPage
