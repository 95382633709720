import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../layout/Navbar'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import Footer from '../layout/Footer'
import ParticlesBackground from '../particlesBackground'
import { useTranslation } from 'react-i18next'

const HomePage = () => {
  const { t } = useTranslation();

  return (
    <div className="animate__animated animate__fadeIn animate__slow">
      <ParticlesBackground />
      <Navbar />
      <div className="px-8  md:px-24 lg:px-36 lg:my-24 xl:my-28  lg:flex lg:items-center">
        <div className="grid grid-cols-1 lg:grid-cols-2 items-center md:gap-x-10 gap-y-10">
          <div className="order-last lg:order-first animate__animated animate__fadeInLeft">
            <div className="border-2  rounded-lg border-dashed">
              <p className="text-2xl md:text-3xl xl:text-4xl text-white p-4">
                {t("Home-content")}
              </p>
            </div>
            <Link
              to={'/about'}
              className="bg-white px-7 py-2 rounded-full uppercase mr-6 md:mr-0 float-right mt-4 rounded-br-none hover:bg-black hover:text-white duration-300"
            >
              {t("more")}
            </Link>
          </div>
          <div className="basis-2/3 animate__animated animate__fadeInRight">
            <Swiper
              pagination={{
                dynamicBullets: true,
              }}
              modules={[Pagination, Autoplay]}
              className="mySwiper"
              loop={true}
              autoplay={true}
            >
              <SwiperSlide>
                <img src="images/6.jpg" alt="" className="rounded-xl" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="images/1.jpg" alt="" className="rounded-xl" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="images/7.jpg" alt="" className="rounded-xl" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="images/10.jpg" alt="" className="rounded-xl" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default HomePage
